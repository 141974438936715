import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {QuizService} from './../services/quiz.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit {

	formData: FormGroup;
	view: string = "result";
	userId: any;

	constructor(public appService: QuizService, public cd: ChangeDetectorRef, public router: Router, private route: ActivatedRoute) {
		this.formData = new FormGroup({
			email: new FormControl("", [Validators.required]),
			password: new FormControl("", [Validators.required])
		})
		this.route.queryParams.subscribe(params => {
			let param1 = params['i'];
			if(param1)
			{
				this.view="markCertificate"
				this.userId=param1;
			}
		});
	 }

	 ngOnInit(): void {
		this.reloadPage();
	  }

	  reloadPage() {
		//window.location.reload();
	 }

	ngAfterViewInit() { 
		
	}

	async onSubmit() {
		if(this.formData.invalid) {
			Swal.fire('Error', "Kindly fill up the required fields.", 'error');
		}
		else {
			let status = await this.appService.login({
				mobile: this.formData.value.email,
				uid: this.formData.value.password,
			}).toPromise().then((res: any) => {
				console.log("res :: ", res)
				if(res.success)
				{
					sessionStorage.setItem('userInfo', JSON.stringify(res.user));
					this.cd.detectChanges();
					return true;
					
				}
			},
			err => {
				console.log("err :: ", err)
				Swal.fire('Error', err.error.message, 'error');
				return false;
		  })
		   if(status)
		   	this.router.navigate(['/quiz'])
		}
	}

	async viewResult() {
		if(this.formData.invalid) {
			Swal.fire('Error', "Kindly fill up the required fields.", 'error');
		}
		else {
			let status = await this.appService.resultLogin({
				mobile: this.formData.value.email,
				uid: this.formData.value.password,
			}).toPromise().then((res: any) => {
				console.log("res :: ", res)
				if(res.success)
				{
					this.userId = res.user.id;
					this.view="markCertificate";
					//window.location.href = 'https://backend.itlit.in/m/' + res.user.id;
					
				}
			},
			err => {
				console.log("err :: ", err)
				Swal.fire('Error', err.error.message, 'error');
				return false;
		  })
		   if(status)
		   	this.router.navigate(['/quiz'])
		}
	}

	examLogin(input: any)
	{
		this.view = input;
	}

	marksheet()
	{
		window.open('https://itlit.in/m/' + this.userId, "_blank");
	}

	certificate()
	{
		window.open('https://itlit.in/c/' + this.userId, "_blank");
	}

}
